.about-section {
    display: flex;
    align-items: flex-start; 
    justify-content: center;
    min-height: 100vh;
    padding: 40px;
    background-color: #f5f5f5; /* Light background */
  }
  
  .image-container {
    flex: 1;
    max-width: 50%;
    margin-right: 40px;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.5s ease-in-out;
  }
  
  .image-container img:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
  
  .text-container {
    flex: 1;
    max-width: 50%;
    margin-top: 80px;
  }
  
  .text-container h1 {
    font-size: 2.5rem;
    font-weight: 400; /* Medium weight */
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .text-container p {
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 15px;
  }
  
  .explore-button {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .explore-button:hover {
    background-color: #555;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .about-section {
      flex-direction: column;
    }
    
    .image-container {
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    .text-container {
      max-width: 100%;
      padding-left: 0;
    }
  
    .text-container h1 {
      font-size: 2rem;
    }
  }
  